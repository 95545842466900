<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <company-submenu menu="WhyJoinMalabs"></company-submenu>

      
      <div class="submenu-content">
        <!-- image -->
        <img v-if="whyMalabs_banner" 
          :src="whyMalabs_banner.src"
          style="width:100%">
          <br><br>
            <div class="row mb-3">
                <div class="col-md-9">
                  
                    <div v-for="block in whyMalabs_block" :key="block">
                        <div  class="row mb-3">
                            <img class="col-3"
                                v-if="block[0]"
                                :src="block[0].src"
                                style="width:140px;height:92px"
                            />
                            <div class="col-8">
                              <h6>{{ block[1] }}</h6>
                              <div v-html="block[2]" class="paragraph"></div>
                            </div>
                        </div>
                        <br><br>
                    </div>
                      
                </div>
                <div class="col-3">
                    <br>
                    <p style="font-weight:bold;">Learn More</p>
                    <hr style="border:2px solid #ccc;margin:-4px 0 10px 0;color: #ffffff;">
                    <p style="color:blue;font-size:90%;"> <router-link :to="{ name: 'JobOpenings'}">Job Openings</router-link></p>
                    <p style="color:blue;font-size:90%;"><a href="https://www.eeoc.gov/eeo-policy-statement" target="_blank">Equal Opportunity Employer</a></p>
                    <p style="color:blue;font-size:90%;"><a href="https://www.e-verify.gov/" target="_blank">E-Verify</a></p>
                    <p style="font-weight:bold; padding-top:20px">Stay Connected</p>
                    <hr style="border:2px solid #ccc;margin:-4px 0 10px 0;color: #ffffff;">
                    <a href="https://www.linkedin.com/company/malabs" target="_blank"><img src="@/assets/images/linkedin_icon.jpg" :height="50"></a> &nbsp
                    <a href="https://www.glassdoor.com/Overview/Working-at-MA-Laboratories-EI_IE3274.11,26.htm" target="_blank"><img src="@/assets/images/glassdoor_icon.jpg" :height="50"></a>
                    <br><br>
                    <img src="@/assets/images/Forbes_2020_Award.png" :height="150" :width="215">
                </div>
            </div>
      </div> 

  </div>
</template>

<script>
import CompanySubmenu from '../../components/nav/CompanySubmenu.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { getAPI } from '../../utils/axios-api'
import { ref } from 'vue'

export default {
  name: 'WhyJoinMalabs',
  components: { CompanySubmenu, BreadcrumbPage },
  setup() {
    const whyMalabs_banner = ref("")
    const whyMalabs_block = ref([])
    const error = ref(null)

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Company", link: ""})
    breadcrumb_links.value.push({text: "Careers", link: "Careers"})
    breadcrumb_links.value.push({text: "Why Join Ma Labs", link: "WhyJoinMalabs"})

    let whyMalabs_url = 'content/pages/45'
    getAPI.get(whyMalabs_url).then((response) => {  
            if(response.status === 200){
                whyMalabs_banner.value = response.data.body[0].value.large
                // console.log(whyMalabs_banner.value)

                let blocks = response.data.body
                for(let index in blocks){
                    if (blocks[index].type == "block"){
                        // console.log(blocks[index].value.heading)
                        whyMalabs_block.value.push([blocks[index].value.image.medium,
                                                    blocks[index].value.heading,
                                                    blocks[index].value.paragraph])

                    }
                }
                console.log(whyMalabs_block.value)

                
                                   
            }
        })
        .catch(err => {  
            error.value = "No page Found"        
        })
        return { whyMalabs_banner,whyMalabs_block,error, breadcrumb_links }
  }
}
</script>

<style scoped>
a{
  color: #555;
}
</style>